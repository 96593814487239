/* Scoped styles for the LoadingPage component */
.loading-page .loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
  font-family: Arial, sans-serif;
  font-size: 2rem;
  font-weight: bold;
  transition: opacity 1s ease-in-out; /* Add transition for opacity */
}

.loading-page .loading-container.fade-out {
  opacity: 0; /* Fade out by setting opacity to 0 */
}

.loading-page .logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  margin-bottom: 20px; /* Adjust spacing as needed */
  opacity: 0;
  animation: fadeInLogo 2s forwards; /* Animation for fading in the logo */
}

.loading-page .logo {
  width: 100%;
  max-width: 500px; /* Adjust maximum width as needed */
  height: auto; /* Maintain aspect ratio */
}

.loading-page .words {
  display: flex;
  justify-content: space-around; /* Ensure equal spacing between words */
  width: 100%; /* Ensure the flex container takes the full width */
  max-width: 600px; /* Adjust as needed */
}

.loading-page .word {
  flex: 1; /* Ensure each word takes equal space */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px; /* Set a fixed height for the words container */
  opacity: 0; /* Start hidden */
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  transform: translateY(20px); /* Slight upward movement for smoother effect */
  color: #1d1d25; /* Apply theme color */
}

.loading-page .word.visible {
  opacity: 1;
  transform: translateY(0); /* Back to original position */
}

@keyframes fadeInLogo {
  to {
    opacity: 1;
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .loading-page .loading-container {
    font-size: 1.5rem; /* Adjust font size for tablets */
  }

  .loading-page .logo {
    max-width: 350px; /* Adjust maximum width for tablets */
  }

  .loading-page .words {
    max-width: 80%; /* Adjust max-width for tablets */
  }

  .loading-page .word {
    height: 40px; /* Adjust height for tablets */
  }
}

@media (max-width: 480px) {
  .loading-page .loading-container {
    font-size: 1.2rem; /* Adjust font size for mobile phones */
  }

  .loading-page .logo {
    max-width: 300px; /* Increase maximum width for mobile phones */
  }

  .loading-page .words {
    max-width: 90%; /* Adjust max-width for mobile phones */
    flex-direction: column; /* Stack words vertically on small screens */
  }

  .loading-page .word {
    height: 30px; /* Adjust height for mobile phones */
  }
}
